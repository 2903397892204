<template>
  <ion-page id="collectorCardDetail">
    <ion-content :fullscreen="true">
      <topbar
        :class="{
          'gradient30 fixed': !$store.state.isPC,
          'top-bar-white': $store.state.isPC,
        }"
        :bigtitle="false"
        :showBack="true"
        :showClose="false"
        :share="$store.state.isPC ? false : true"
        :shareData="shareData"
      ></topbar>

      <div class="pc-box margin-top-0 pc-box2">
        <div class="pc-min-content-pct">
          <div class="pc-page-title" v-if="$store.state.isPC">Collector Card</div>

          <div
            class="content collectorCardDetail"
            :style="$store.state.isPC ? 'padding: 40px 0' : ''"
          >
            <div class="collectorCard-img" v-if="!$store.state.isPC">
              <img
                v-lazy="collectorCardDetail.coverImg"
                :key="collectorCardDetail.coverImg"
              />
            </div>
            <van-tabs
              v-model="activeName"
              style="height: 60px"
              :ellipsis="false"
              @click-tab="tabsEvent"
            >
              <van-tab title="Card" name="Stamps">
                <div
                  class="section"
                  :class="{ 'pc-content-flex section-pc': $store.state.isPC }"
                >
                  <div class="collectorCard-img" v-if="$store.state.isPC">
                    <img
                      v-lazy="collectorCardDetail.coverImg"
                      :key="collectorCardDetail.coverImg"
                    />
                  </div>

                  <div class="collectorCard-info" style="border-radius: 0; margin-top: 0">
                    <div class="collectorCard-type">
                      <img src="@/assets/collectorCardIcon.png" />
                      <span>Collector Card</span>
                    </div>
                    <div class="collectorCard-name main-color font-24 Bold pc-font-28">
                      <span>{{ collectorCardDetail.name }}</span>
                    </div>

                    <div v-if="$store.state.isPC">
                      <div class="hr"></div>
                      <div class="period">
                        <label class="Bold pc-font-28">Redemption Period</label>
                        <span class="font-16">
                          <template
                            v-if="
                              collectorCardDetail.collectStampQty ==
                              collectorCardDetail.singleCardCollStampQty
                            "
                          >
                            {{
                              formatDate(
                                collectorCardDetail.createdTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          <template v-else>
                            {{
                              formatDate(
                                collectorCardDetail.redeemStartTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          -
                          {{
                            formatDate(
                              collectorCardDetail.redeemEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                      <div class="period">
                        <label class="Bold pc-font-28">Promotion Runs</label>
                        <span class="font-16">
                          <template
                            v-if="
                              collectorCardDetail.collectStampQty ==
                              collectorCardDetail.singleCardCollStampQty
                            "
                          >
                            {{
                              formatDate(
                                collectorCardDetail.createdTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          <template v-else>
                            {{
                              formatDate(
                                collectorCardDetail.collStartTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          -
                          {{
                            formatDate(
                              collectorCardDetail.collEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="solid-hr" style="width: 100%; height: 1.5px"></div>

                <div
                  class="section"
                  :class="{ 'section-pc': $store.state.isPC }"
                  style="padding-top: 10px; background-color: #fff"
                >
                  <ion-card class="stamp-card">
                    <ion-card-header class="card-header">
                      <ion-card-title
                        class="stampCardTitle Bold"
                        :class="{ 'main-color': $store.state.isPC }"
                      >
                        <span class="pc-font-24">You have earned：</span>
                        <!-- <p style="font-size: 14px; color: #a6a6a6">Only stamps earned from purchase are transferrable!</p> -->
                        <div class="stamp-circle">
                          <div class="stamp-num font-16">
                            {{ collectorCardDetail.collectStampQty }}/{{
                              collectorCardDetail.singleCardCollStampQty
                            }}
                          </div>
                        </div>
                      </ion-card-title>
                    </ion-card-header>

                    <div class="card-content">
                      <div
                        class="detail-contain"
                        :style="{
                          height: cardAreaHeight + 'px',
                        }"
                      >
                        <div
                          class="detail-contain-item"
                          v-for="(
                            card, index
                          ) in collectorCardDetail.singleCardCollStampQty"
                          :key="index"
                        >
                          <div
                            class="detail-contain-itemIn stampsModule"
                            v-show="!collectCardArray[index].stampImg"
                            ref="numItem"
                          >
                            {{ collectCardArray[index].num }}
                          </div>
                          <div
                            class="detail-contain-itemIt stampsModule"
                            v-show="collectCardArray[index].stampImg"
                            ref="numItem"
                          >
                            <img
                              class="detail-contain-itemImg"
                              v-lazy="collectCardArray[index].stampImg"
                              :key="collectCardArray[index].stampImg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="hr"></div>
                      <div class="btn-group" style="padding: 4px">
                        <ion-button
                          color="primary"
                          v-if="
                            collectorCardDetail.canRedeem &&
                            collectorCardDetail.collectStampQty ==
                              collectorCardDetail.singleCardCollStampQty &&
                            $store.state.appVersion
                          "
                          style="margin-bottom: 20px"
                          @click="redeemCollectorCard('Redeem')"
                          >Redeem</ion-button
                        >
                        <ion-button
                          color="primary"
                          v-if="
                            !(
                              collectorCardDetail.canRedeem &&
                              collectorCardDetail.collectStampQty ==
                                collectorCardDetail.singleCardCollStampQty
                            ) && $store.state.appVersion
                          "
                          style="margin-bottom: 20px"
                          @click="redeemCollectorCard('Collect')"
                          >Collect</ion-button
                        >
                        <ion-button
                          class="transfer-btn"
                          v-if="$store.state.appVersion"
                          @click="transferCollectorCard"
                          >Transfer</ion-button
                        >
                      </div>
                    </div>
                    <div class="circles" v-if="!$store.state.isPC"></div>
                    <div
                      class="circles"
                      style="left: auto; right: -11px"
                      v-if="!$store.state.isPC"
                    ></div>
                  </ion-card>
                </div>
              </van-tab>
              <van-tab title="Details" name="Rewards">
                <div style="background: #f2f2f2">
                  <div
                    class="section"
                    :class="{ 'pc-content-flex section-pc': $store.state.isPC }"
                  >
                    <div class="collectorCard-img" v-if="$store.state.isPC">
                      <img
                        v-lazy="collectorCardDetail.coverImg"
                        :key="collectorCardDetail.coverImg"
                      />
                    </div>

                    <div
                      class="collectorCard-info"
                      style="border-radius: 0; margin-top: 0"
                    >
                      <div class="collectorCard-type">
                        <img src="@/assets/collectorCardIcon.png" />
                        <span>Collector Card</span>
                      </div>
                      <div class="collectorCard-name main-color font-24 Bold pc-font-28">
                        <span>{{ collectorCardDetail.name }}</span>
                      </div>
                      <div class="hr"></div>
                      <div class="period">
                        <label class="Bold pc-font-28">Redemption Period</label>
                        <span class="font-16">
                          <template
                            v-if="
                              collectorCardDetail.collectStampQty ==
                              collectorCardDetail.singleCardCollStampQty
                            "
                          >
                            {{
                              formatDate(
                                collectorCardDetail.createdTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          <template v-else>
                            {{
                              formatDate(
                                collectorCardDetail.redeemStartTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          -
                          {{
                            formatDate(
                              collectorCardDetail.redeemEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                      <div class="period">
                        <label class="Bold pc-font-28">Promotion Runs</label>
                        <span class="font-16">
                          <template
                            v-if="
                              collectorCardDetail.collectStampQty ==
                              collectorCardDetail.singleCardCollStampQty
                            "
                          >
                            {{
                              formatDate(
                                collectorCardDetail.createdTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          <template v-else>
                            {{
                              formatDate(
                                collectorCardDetail.collStartTime,
                                $store.state.form.merchantDetail.dateFormat
                              )
                            }}
                          </template>
                          -
                          {{
                            formatDate(
                              collectorCardDetail.collEndTime,
                              $store.state.form.merchantDetail.dateFormat
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="solid-hr" style="width: 100%; height: 1.5px"></div>
                  <div class="section" :class="{ 'section-pc': $store.state.isPC }">
                    <div class="offer-details">
                      <div class="title Bold pc-font-28">Promo details</div>
                      <showMore
                        :content="collectorCardDetail.descr"
                        :showHeight="!$store.state.isPC ? 70 : 68"
                      ></showMore>
                      <!-- <div class="content font-16" ref="offerDetails" style="height: auto; margin-bottom: 0; padding: 0 20px 30px" v-if="!$store.state.isPC">
                        {{ collectorCardDetail.descr }}
                      </div> -->
                    </div>
                    <div class="tandC" :class="{ 'pc-tandC': $store.state.isPC }">
                      <div class="title Bold pc-font-28">Terms & Conditions</div>
                      <showMore
                        :content="collectorCardDetail.terms"
                        :showHeight="!$store.state.isPC ? 70 : 68"
                      ></showMore>
                    </div>
                  </div>
                </div>
              </van-tab>
            </van-tabs>
          </div>
          <div class="height50"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonCardTitle,
  IonCard,
  IonCardHeader,
  IonButton,
} from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
import showMore from "@/components/showMore.vue";

export default {
  name: "collectorCardDetail",
  mixins: [config.globalMixin],
  data() {
    return {
      collectorCardDetail: {},
      shareData: {},
      collectCardArray: [],
      cards: [],
      activeName: "Stamps",
      cardAreaHeight: "",
      stampNum: [],
    };
  },
  components: {
    IonContent,
    IonPage,
    topbar,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    showMore,
    IonButton,
  },
  methods: {
    async init() {
      const objdata = this.getAuthObj();
      objdata.userStampCardId = this.$route.params.id;
      objdata.apiUrl = this.apiConfig.detailsUserStampCard;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.collectorCardDetail = data.data.result;
      this.collectorCardDetail.collStartTime = this.collectorCardDetail.collStartTime.replaceAll(
        "-",
        "/"
      );
      this.collectorCardDetail.collEndTime = this.collectorCardDetail.collEndTime.replaceAll(
        "-",
        "/"
      );
      this.collectorCardDetail.redeemStartTime = this.collectorCardDetail.redeemStartTime.replaceAll(
        "-",
        "/"
      );
      this.collectorCardDetail.redeemEndTime = this.collectorCardDetail.redeemEndTime.replaceAll(
        "-",
        "/"
      );
      this.collectCardArray = [];
      for (let i = 0; i < this.collectorCardDetail.singleCardCollStampQty; i++) {
        if (i + 1 > this.collectorCardDetail.collectStampQty) {
          this.collectCardArray[i] = {};
          this.collectCardArray[i].num = i + 1;
        } else {
          this.collectCardArray[i] = {};
          this.collectCardArray[i].num = i + 1;
          this.collectCardArray[i].stampImg = this.collectorCardDetail.stampImg;
        }
      }
      if (this.$store.state.isPC) {
        if (this.collectorCardDetail.singleCardCollStampQty <= 80) {
          this.cardAreaHeight =
            (this.collectorCardDetail.singleCardCollStampQty / 5) * 80 + 40;
        } else {
          this.cardAreaHeight = 400;
        }
      } else {
        if (this.collectorCardDetail.singleCardCollStampQty <= 80) {
          this.cardAreaHeight =
            (this.collectorCardDetail.singleCardCollStampQty / 5) * 48 + 40;
        } else {
          this.cardAreaHeight = 236;
        }
      }

      this.cards = this.collectCardArray;
      this.shareData = {
        title: this.$store.state.appName + " - Download and Enjoy Exclusive Offers",
        description: `${this.collectorCardDetail.name}
        Promotion Runs: ${this.formatDate(
          this.collectorCardDetail.collStartTime,
          this.$store.state.form.merchantDetail.dateFormat
        )} - ${this.formatDate(
          this.collectorCardDetail.collEndTime,
          this.$store.state.form.merchantDetail.dateFormat
        )}
        Redemption Period: ${this.formatDate(
          this.collectorCardDetail.redeemStartTime,
          this.$store.state.form.merchantDetail.dateFormat
        )} - ${this.formatDate(
          this.collectorCardDetail.redeemEndTime,
          this.$store.state.form.merchantDetail.dateFormat
        )}`,
        image: this.collectorCardDetail.coverImg,
      };
    },
    tabsEvent(name) {
      this.activeName = name;
    },
    showMore(type) {
      this.$refs[type].style.height = "auto";
    },
    redeemCollectorCard(type) {
      this.$router.push({
        path: "/wallet",
      });
      this.sendGAEvent("Click", "Collector Card Detail", type);
    },

    transferCollectorCard() {
      this.$router.replace({
        path: "/selectFriend",
        query: {
          stamp: this.collectorCardDetail.collectTotalQty,
          stampCardId: this.collectorCardDetail.stampCardId,
          mode: "collect",
        },
      });
      this.sendGAEvent("Click", "Collector Card Detail", "Go Transfer");
    },
  },
  ionViewDidEnter() {
    this.$store.state.exitApp = false;
    this.init();
    this.sendGAPageView("eStamps Detail");
    setTimeout(() => {
      this.$store.state.getNotification = null;
    }, 2000);
  },
};
</script>
