<template>
  <div>
    <!-- overflow: hidden：清除子元素相对父元素的超界溢出 -->
    <div style="padding: 10px 0px 30px">
      <div
        style="overflow: hidden"
        :style="{
          height: showMore
            ? 'auto'
            : isLongContent
            ? showHeight + 'px'
            : contentHeight + 'px',
        }"
      >
        <div
          ref="content"
          class="font-16"
          :style="!$store.state.isPC ? 'padding: 0 20px' : ''"
        >
          <slot>
            <div
              class="wrap collector-content-text"
              @click.prevent="getAelement"
              v-html="content"
            ></div>
          </slot>
        </div>
      </div>
    </div>
    <div
      class="btn font-16 Bold"
      v-show="isLongContent && !showMore"
      :class="{ 'pc-showMore-btn': $store.state.isPC }"
    >
      <span @click="_toggleShowMore">Show more</span>
    </div>
    <div
      class="btn font-16 Bold"
      v-show="showMore"
      :class="{ 'pc-showMore-btn': $store.state.isPC }"
    >
      <span @click="_toggleCloseAll">Close All</span>
    </div>
  </div>
</template>
<script>
import { Browser } from "@capacitor/browser";
export default {
  name: "showMore",
  data() {
    return {
      showMore: false,
      isLongContent: false,
      contentHeight: 90,
    };
  },

  props: {
    showHeight: {
      type: Number,
      required: true,
      default: 200,
    },
    content: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.refresh();
    console.log(Browser);
  },
  watch: {
    content() {
      this._calculateHeight();
    },
  },
  methods: {
    refresh() {
      this._calculateHeight();
    },
    _calculateHeight() {
      this.$nextTick().then(() => {
        this.contentHeight = this.$refs.content.clientHeight;
        console.log(this.contentHeight);
        if (this.contentHeight > this.showHeight) {
          this.isLongContent = true;
        } else {
          this.isLongContent = false;
        }
      });
    },
    _toggleShowMore() {
      this.showMore = !this.showMore;
    },
    _toggleCloseAll() {
      this.showMore = !this.showMore;
    },
    //跳转内外link
    getAelement(e) {
      if (e.target.getAttribute("href") && e.target.tagName == "A") {
        let link = e.target.getAttribute("href");
        if (
          link.search("http") > -1 ||
          link.search("www") > -1 ||
          link.search("com") > -1
        ) {
          Browser.open({ url: link });
        } else {
          console.log(link);
          this.$router.push({
            path: "/" + link,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
